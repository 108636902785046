export const FEATURE_TOGGLE_TYPE = {
  prd: 'production',
  stg: 'staging',
  dev: 'development',
  demo: 'demo',
} as const

export type Feature = typeof FEATURE_TOGGLE_TYPE[keyof typeof FEATURE_TOGGLE_TYPE]

export const isDevelopment = (feature: Feature) => feature === FEATURE_TOGGLE_TYPE.dev

// デモ環境向けの機能の出しわけのフラグ
// デモ環境向けだとしても開発環境では、機能が全て見える状態であった方が良いため開発環境でも利用できるようにしている
export const isDevelopmentOrDemo = (feature: Feature) =>
  feature === FEATURE_TOGGLE_TYPE.dev || feature === FEATURE_TOGGLE_TYPE.demo

export const isDemo = (feature: Feature) => feature === FEATURE_TOGGLE_TYPE.demo

/**
 * XYSAAS-4256 ゲストリストアップ向けのCL画面機能の有効化フラグ
 * XYSAAS-9989 ゲストリストアップのカップル・クライアントにおいて、開催日・担当PL名の概念を登録・表示・検索できるようにする の有効化フラグ
 */
export const isEnabledCLWebForGuestListUp = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-10047 CSおよびCL向けに新規決済あり招待状が作れなくなる告知を出す機能の有効化フラグ
 */
export const isEnabledAnnoucementForPaymentStop = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-9789 ブックマーク訴求表示 の有効化フラグ
 */
export const isEnabledBookmarkToast = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-10166 クライアントが既存の導線から利用申込できない機能の有効化フラグ
 */
export const isEnabledEntryFormTemporaryClosing = (feature: Feature) => isDevelopment(feature)
