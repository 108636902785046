/**
 * DesignSystemで定義されているクライアントのカラー
 * 下記Figmaを参照のこと
 * https://www.figma.com/design/PGEpnOfpXMI1WyuARx5ZST/%F0%9F%92%92-%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88_DesignSystem(%E4%BD%9C%E6%A5%AD%E4%B8%AD)?node-id=1-3&t=JFB5A5oQtsOXfqpT-1
 */

/**
 * CLWebのPrimitive Color。
 * こちらは直接読み出さず、cl_colorsを使うこと。
 */
const primitive_colors = {
  white_100: '#FFFFFF',
  white_70: 'rgba(255, 255, 255, 0.7)',
  white_30: 'rgba(255, 255, 255, 0.3)',
  gray_100: '#595959',
  gray_90: '#6D6D6D',
  gray_80: '#8D8D8D',
  gray_70: '#979797',
  gray_60: '#B8B8B8',
  gray_50: '#CDCDCD',
  gray_40: '#D8D8D8',
  gray_30: '#E5E5E5',
  gray_10: '#F8F8F8',
  pink_100: '#EA6077',
  pink_90: '#EB6D7B',
  pink_80: '#F58CA1',
  pink_70: '#F59CAE',
  pink_60: '#F8AFBD',
  pink_50: '#F8BAC6',
  pink_20: '#FDECEE',
  pink_10: '#FFF8F9',
  gold_100: '#6C605A',
  gold_70: '#C09C5E',
  gold_50: '#D2B88A',
  gold_40: 'rgba(210, 184, 138, 0.7)',
  gold_10: 'rgba(241, 238, 231, 0.5)',
  blue_100: '#435B67',
  blue_80: '#3985D0',
  blue_70: '#7AADE8',
  blue_30: '#CFD2D9',
  red_100: '#D50000',
}

/** CLWebのSemantic Color */
export const cl_colors = {
  text: {
    default: primitive_colors.gray_100,
    secondary: primitive_colors.gray_80,
    placeholder: primitive_colors.gray_40,
    white: primitive_colors.white_100,
    zexy_pink: primitive_colors.pink_100,
    btn_pink_label: primitive_colors.pink_90,
    label_invitation_status: primitive_colors.gold_70,
    link: primitive_colors.blue_80,
    alert: primitive_colors.red_100,
    date_holiday: primitive_colors.pink_70,
    date_saturday: primitive_colors.blue_70,
    r_copyright: primitive_colors.blue_100,
  },
  bg: {
    body: primitive_colors.gray_10,
    white: primitive_colors.white_100,
    assistive: primitive_colors.gold_10,
    notification: primitive_colors.pink_20,
    table_hover_selected: primitive_colors.pink_20,
    table_absence: primitive_colors.gray_40,
    leftmenu: primitive_colors.pink_70,
    leftmenu_hover: primitive_colors.pink_50,
    leftmenu_2nd_layer: primitive_colors.pink_80,
    leftmenu_2nd_layer_hover: primitive_colors.pink_60,
    input_on: primitive_colors.pink_100,
    btn_primary: primitive_colors.pink_90,
    btn_hover: primitive_colors.white_30,
    btn_disable: primitive_colors.white_70,
    label_payment: primitive_colors.gold_50,
    label_payment_stop: primitive_colors.gray_70,
    label_online_payment_on: primitive_colors.gold_40,
    label_online_payment_off: primitive_colors.gray_60,
    textfiled_error: primitive_colors.pink_10,
    label_partner_on: primitive_colors.gold_10,
    label_partner_off: primitive_colors.pink_20,
  },
  border: {
    default: primitive_colors.gray_50,
    secondary: primitive_colors.gray_30,
    selected: primitive_colors.pink_100,
    btn_secondary: primitive_colors.pink_90,
    btn_sub: primitive_colors.gray_40,
    label_invitation_status: primitive_colors.gold_50,
    error: primitive_colors.red_100,
  },
}
