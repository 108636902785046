import Footer from '@/components/cl/footer'
import Header from '@/components/cl/header'
import Loading from '@/components/cl/loading'
import { useLoading } from '@/context/loading'
import { fontFamily, zIndexModal } from '@/styles/cl/styleConstants'
import { css } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'

type GenerateStyleType = {
  backgroundColor: string
  paddingBottom: string
  isSideMenuOpen: boolean
}

type LayoutType = {
  children?: React.ReactNode
  sideMenu?: ({ opened }: { opened: boolean }) => React.JSX.Element
  isSideMenuOpen?: boolean
  backgroundColor: string
  /* 画面下部に固定で表示するフロートボタンを表示する際に指定する */
  paddingBottom?: string
  logoLink?: string
  /* 全体ローディングを使用したくないページで指定する */
  isLoadingEnabled?: boolean
  /* header/footerを非表示にする */
  isHideHeaderFooter?: boolean
  isClPreview?: boolean
}

export default function Layout({
  children,
  sideMenu,
  isSideMenuOpen = false,
  backgroundColor,
  paddingBottom = '0',
  logoLink,
  isLoadingEnabled = true,
  isHideHeaderFooter = false,
  isClPreview = false,
}: LayoutType) {
  const styles = generateStyle({ backgroundColor, paddingBottom, isSideMenuOpen })
  const { loading } = useLoading()

  return (
    <ThemeProvider theme={theme}>
      {isClPreview ? (
        <>{children}</>
      ) : (
        <div css={styles.wrapper}>
          {sideMenu && sideMenu({ opened: isSideMenuOpen })}
          <div css={styles.mainWrapper}>
            {!isHideHeaderFooter && <Header logoLink={logoLink} />}
            {children}
            {!isHideHeaderFooter && <Footer />}
          </div>
          {isLoadingEnabled && loading && (
            <div css={styles.loading}>
              <Loading size={58} thickness={6} />
            </div>
          )}
        </div>
      )}
    </ThemeProvider>
  )
}

const generateStyle = ({ backgroundColor, paddingBottom, isSideMenuOpen }: GenerateStyleType) => ({
  wrapper: css`
    display: flex;
    font-family: ${fontFamily};
  `,
  loading: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndexModal + 1};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  `,
  mainWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: ${isSideMenuOpen ? '1040px' : '1220px'};
    min-height: 100vh;
    padding-bottom: ${paddingBottom}px;
    background-color: ${backgroundColor};
  `,
})

const theme = createTheme({
  typography: {
    fontFamily: [fontFamily].join(','),
  },
})
