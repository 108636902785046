import { css } from '@emotion/react'

const FONT_WEIGHTS = {
  BOLD: 700,
  REGULAR: 400,
} as const
type FONT_WEIGHT = typeof FONT_WEIGHTS[keyof typeof FONT_WEIGHTS]

type TextStyleBaseProps = {
  size: number
  weight: FONT_WEIGHT
  height: number
  color: string
  space: number
}

const textStyleBase = ({ size, weight, height, space, color }: TextStyleBaseProps) => css`
  font-size: ${size}px;
  font-style: normal;
  font-weight: ${weight};
  line-height: ${height};
  color: ${color};
  letter-spacing: ${space}px;
`

const alphanumericStyle = (textStyleBaseProp: TextStyleBaseProps) => [
  textStyleBase(textStyleBaseProp),
]

/**
 * CL WebのText style.
 * 下記FigmaのDesignSystemで定義されている.
 * https://www.figma.com/design/PGEpnOfpXMI1WyuARx5ZST/%F0%9F%92%92-%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88_DesignSystem(%E4%BD%9C%E6%A5%AD%E4%B8%AD)?node-id=1-4&t=JFB5A5oQtsOXfqpT-1
 */
export const ClTextStyles = {
  heading: {
    bold: {
      s24px: {
        h100: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s20px: {
        h100: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s18px: {
        h100: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
    },
  },
  body: {
    regular: {
      s28px: {
        h100: (color: string) =>
          textStyleBase({
            size: 28,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s18px: {
        h100: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s16px: {
        h150: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s14px: {
        h150: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s12px: {
        h150: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s11px: {
        h150: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s10px: {
        h150: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
    },
    bold: {
      s20px: {
        h120: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
      },
      s16px: {
        h150: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s14px: {
        h150: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s12px: {
        h150: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s11px: {
        h150: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s10px: {
        h150: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
    },
  },
  alphanumeric: {
    regular: {
      s70px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 70,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s56px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 56,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s42px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 42,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s24px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 24,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s22px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 22,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s20px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 20,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s16px: {
        h150: (color: string) =>
          alphanumericStyle({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          alphanumericStyle({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          alphanumericStyle({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
      s14px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0,
          }),
      },
    },
    bold: {
      s56px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 56,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s20px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
      s16px: {
        h100: (color: string) =>
          alphanumericStyle({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0,
          }),
      },
    },
  },
}
