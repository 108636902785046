/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetClientGuestListUpCustomerDetailRequest } from '../model'
// @ts-ignore
import { GetClientGuestListUpCustomerDetailResponse } from '../model'
// @ts-ignore
import { GetClientGuestListUpCustomersRequest } from '../model'
// @ts-ignore
import { GetClientGuestListUpCustomersResponse } from '../model'
// @ts-ignore
import { UpdatePlannerNameRequest } from '../model'
/**
 * ClientGuestListUpCustomerControllerApi - axios parameter creator
 * @export
 */
export const ClientGuestListUpCustomerControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {GetClientGuestListUpCustomerDetailRequest} getClientGuestListUpCustomerDetailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientGuestListUpCustomerDetail: async (
      getClientGuestListUpCustomerDetailRequest: GetClientGuestListUpCustomerDetailRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientGuestListUpCustomerDetailRequest' is not null or undefined
      assertParamExists(
        'getClientGuestListUpCustomerDetail',
        'getClientGuestListUpCustomerDetailRequest',
        getClientGuestListUpCustomerDetailRequest,
      )
      const localVarPath = `/cl/api/getGuestListUpCustomerDetail`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientGuestListUpCustomerDetailRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientGuestListUpCustomersRequest} getClientGuestListUpCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientGuestListUpCustomers: async (
      getClientGuestListUpCustomersRequest: GetClientGuestListUpCustomersRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientGuestListUpCustomersRequest' is not null or undefined
      assertParamExists(
        'getClientGuestListUpCustomers',
        'getClientGuestListUpCustomersRequest',
        getClientGuestListUpCustomersRequest,
      )
      const localVarPath = `/cl/api/getClientGuestListUpCustomers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientGuestListUpCustomersRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdatePlannerNameRequest} updatePlannerNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlannerName: async (
      updatePlannerNameRequest: UpdatePlannerNameRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePlannerNameRequest' is not null or undefined
      assertParamExists('updatePlannerName', 'updatePlannerNameRequest', updatePlannerNameRequest)
      const localVarPath = `/cl/api/updatePlannerName`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePlannerNameRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientGuestListUpCustomerControllerApi - functional programming interface
 * @export
 */
export const ClientGuestListUpCustomerControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ClientGuestListUpCustomerControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {GetClientGuestListUpCustomerDetailRequest} getClientGuestListUpCustomerDetailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientGuestListUpCustomerDetail(
      getClientGuestListUpCustomerDetailRequest: GetClientGuestListUpCustomerDetailRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetClientGuestListUpCustomerDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientGuestListUpCustomerDetail(
        getClientGuestListUpCustomerDetailRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientGuestListUpCustomersRequest} getClientGuestListUpCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientGuestListUpCustomers(
      getClientGuestListUpCustomersRequest: GetClientGuestListUpCustomersRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetClientGuestListUpCustomersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientGuestListUpCustomers(
        getClientGuestListUpCustomersRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdatePlannerNameRequest} updatePlannerNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePlannerName(
      updatePlannerNameRequest: UpdatePlannerNameRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlannerName(
        updatePlannerNameRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ClientGuestListUpCustomerControllerApi - factory interface
 * @export
 */
export const ClientGuestListUpCustomerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientGuestListUpCustomerControllerApiFp(configuration)
  return {
    /**
     *
     * @param {GetClientGuestListUpCustomerDetailRequest} getClientGuestListUpCustomerDetailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientGuestListUpCustomerDetail(
      getClientGuestListUpCustomerDetailRequest: GetClientGuestListUpCustomerDetailRequest,
      options?: any,
    ): AxiosPromise<GetClientGuestListUpCustomerDetailResponse> {
      return localVarFp
        .getClientGuestListUpCustomerDetail(getClientGuestListUpCustomerDetailRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientGuestListUpCustomersRequest} getClientGuestListUpCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientGuestListUpCustomers(
      getClientGuestListUpCustomersRequest: GetClientGuestListUpCustomersRequest,
      options?: any,
    ): AxiosPromise<GetClientGuestListUpCustomersResponse> {
      return localVarFp
        .getClientGuestListUpCustomers(getClientGuestListUpCustomersRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdatePlannerNameRequest} updatePlannerNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlannerName(
      updatePlannerNameRequest: UpdatePlannerNameRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updatePlannerName(updatePlannerNameRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ClientGuestListUpCustomerControllerApi - object-oriented interface
 * @export
 * @class ClientGuestListUpCustomerControllerApi
 * @extends {BaseAPI}
 */
export class ClientGuestListUpCustomerControllerApi extends BaseAPI {
  /**
   *
   * @param {GetClientGuestListUpCustomerDetailRequest} getClientGuestListUpCustomerDetailRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientGuestListUpCustomerControllerApi
   */
  public getClientGuestListUpCustomerDetail(
    getClientGuestListUpCustomerDetailRequest: GetClientGuestListUpCustomerDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientGuestListUpCustomerControllerApiFp(this.configuration)
      .getClientGuestListUpCustomerDetail(getClientGuestListUpCustomerDetailRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientGuestListUpCustomersRequest} getClientGuestListUpCustomersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientGuestListUpCustomerControllerApi
   */
  public getClientGuestListUpCustomers(
    getClientGuestListUpCustomersRequest: GetClientGuestListUpCustomersRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientGuestListUpCustomerControllerApiFp(this.configuration)
      .getClientGuestListUpCustomers(getClientGuestListUpCustomersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdatePlannerNameRequest} updatePlannerNameRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientGuestListUpCustomerControllerApi
   */
  public updatePlannerName(
    updatePlannerNameRequest: UpdatePlannerNameRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientGuestListUpCustomerControllerApiFp(this.configuration)
      .updatePlannerName(updatePlannerNameRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
