import { PrimaryButton, SimpleButton } from '@/components/cl/button'
import { loggingEntryCustomEvent } from '@/components/common/googleTagManager'
import { EntryModalWrapper } from '@/components/entry/entryModalWrapper'
import { useError } from '@/context/error'
import { useLogout } from '@/data/hooks/entry/useLogout'
import { colors } from '@/styles/colors'
import { useEntryFormInputValuesOnStorage } from '@/utils/entry/sessionStorageUtil'
import { css } from '@emotion/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

type EntryCloseModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function EntryCloseModal({ open, setOpen }: EntryCloseModalProps) {
  const router = useRouter()
  const { logout, success, error } = useLogout()
  const { setError } = useError()
  const { entryFormInputValuesOnStorage } = useEntryFormInputValuesOnStorage()

  useEffect(() => {
    if (open) {
      /** 未申込会場ありモーダルを表示した際にログを計測 */
      loggingEntryCustomEvent({
        event: 'entry_not_complete_confirm_modal',
        cl_userid: entryFormInputValuesOnStorage?.userId,
        houjin_cd: entryFormInputValuesOnStorage?.houjinInfo?.houjinCd,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (success) {
      router.push('/entry')
    } else if (error) {
      setError(error.statusCode, error.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error])

  const onClickCloseButton = () => {
    /** モーダルの「終了する」ボタンをクリックした際に計測 */
    loggingEntryCustomEvent({
      event: 'entry_not_complete_confirm_close_tap',
      cl_userid: entryFormInputValuesOnStorage?.userId,
      houjin_cd: entryFormInputValuesOnStorage?.houjinInfo?.houjinCd,
    })
    logout()
  }

  const onContinueEntry = () => {
    setOpen(false)
    /** 「申込を続ける」または「モーダル外部をクリック」してモーダルをクローズした際に計測 */
    loggingEntryCustomEvent({
      event: 'entry_not_complete_confirm_continue_tap',
      cl_userid: entryFormInputValuesOnStorage?.userId,
      cl_cd: entryFormInputValuesOnStorage?.entryTargetClient?.clientCd,
      houjin_cd: entryFormInputValuesOnStorage?.houjinInfo?.houjinCd,
    })
  }

  return (
    <EntryModalWrapper open={open} onClose={onContinueEntry}>
      <div css={styles.heading}>
        未申込状態の会場があります
        <br />
        申込を終了しますか？
      </div>
      <div css={styles.text}>終了すると法人情報から再度入力していただくことになります</div>
      <div css={styles.buttonWraper}>
        <div css={styles.flex}>
          <Link href={'/entry/'} legacyBehavior>
            <SimpleButton css={styles.modalButtonStyle} onClick={onClickCloseButton}>
              終了する
            </SimpleButton>
          </Link>
          <PrimaryButton css={styles.modalButtonStyle} onClick={onContinueEntry}>
            申込を続ける
          </PrimaryButton>
        </div>
      </div>
    </EntryModalWrapper>
  )
}

const styles = {
  heading: css`
    font-size: 24px;
    font-weight: 600;
    color: ${colors.COLOR_595959};
  `,
  text: css`
    margin-top: 16px;
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    color: ${colors.COLOR_595959};
  `,
  buttonWraper: css`
    width: 100%;
    margin-top: 48px;
  `,
  flex: css`
    display: flex;
    gap: 24px;
    justify-content: space-around;
  `,
  modalButtonStyle: css`
    width: 243px;
    height: 56px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 30px;
  `,
}
