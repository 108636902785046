import { krtIdentifyUser } from '@/components/common/karte/karteTagProvider'
import {
  GuestListUpCoupleUser,
  GuestListUpCoupleUserContextProvider,
} from '@/context/cs_guest_list_up/session'
import { GuestListUpCoupleSessionControllerApi } from '@/data/gen'
import { defaultConfiguration, invoke, RPCError } from '@/data/rpc'
import { ReactNode, useEffect, useState } from 'react'

type Props = {
  children: ReactNode
}

/**
 * GuestListUpにおけるCoupleの認証状態を確認して、認証状態をContextに保存します。
 * 配下のComponentでログイン状態による制御を行う際はContextから取得してださい。
 *
 * ※Contextがないことによるエラーを防ぐため、
 * cs_guest_list_up配下のページ/レイアウトを作成の際はルートに本コンポーネントを設定してください。
 */
export const Authentication = ({ children }: Props) => {
  const [user, setUser] = useState<GuestListUpCoupleUser | null>(null)
  const [error, setError] = useState<RPCError | null>(null)
  const checkSession = async () => {
    const api = new GuestListUpCoupleSessionControllerApi(defaultConfiguration())
    await invoke(() => api.checkGuestListUpCoupleSession()).then(async (response) => {
      if (response.success) {
        const { coupleUUID, partnerUUID } = response.success
        setUser({ coupleUUID, partnerUUID })
        krtIdentifyUser({ user_id: partnerUUID })
        setError(null)
        return
      }
      setUser(null)
      setError(response.error)
    })
  }

  useEffect(() => {
    if (user) {
      // 再来訪時にもユーザーが識別されるよう明示的にIDを送付する
      krtIdentifyUser({ user_id: user.partnerUUID })
    } else {
      checkSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GuestListUpCoupleUserContextProvider user={user} error={error}>
      {(user || error) && children}
    </GuestListUpCoupleUserContextProvider>
  )
}
