import { colors } from '@/styles/colors'
import { css } from '@emotion/react'

/**
 * CL WebのFont family.
 * 下記FigmaのDesignSystemで定義されている.
 * https://www.figma.com/design/PGEpnOfpXMI1WyuARx5ZST/%F0%9F%92%92-%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88_DesignSystem(%E4%BD%9C%E6%A5%AD%E4%B8%AD)?node-id=390-88&t=JFB5A5oQtsOXfqpT-4
 */
export const fontFamily =
  'ui-sans-serif,' +
  '-apple-system,' +
  'BlinkMacSystemFont,' +
  'Arial,' +
  '"Hiragino Kaku Gothic ProN",' +
  '"Hiragino Sans",' +
  '"BIZ UDPGothic",' +
  'Meiryo,' +
  'sans-serif;'

// MUIのdrawer相当
export const zIndexSidebar = 1200
// TODO: XYSAAS-8921 entryで使われているため残しているが、cl側の指定(zIndexModal + 1)に統一したい
// see also: src/components/cl/layout.tsx
// see also: src/components/entry/layout.tsx
export const zIndexLoading = 1201
// MUIのmodal相当
export const zIndexModal = 1300
// MUIのsnackbar相当
export const zIndexSnackBar = 1400

/*
 * 本スタイルは複数箇所で利用されており、
 * 変更時の影響が大きいため、変更しないこと。
 * 変更が必要な場合は同様のスタイルを切り出した上で変更すること
 */
export const styleSearchCard = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

  .searchInputs {
    display: flex;
    padding: 24px;
    .MuiOutlinedInput-root {
      height: 40px;
      font-size: 16px;
    }
  }

  .resetButton {
    margin: 28px;
  }
`

/*
 * 本スタイルは複数箇所で利用されており、
 * 変更時の影響が大きいため、変更しないこと。
 * 変更が必要な場合は同様のスタイルを切り出した上で変更すること
 */
export const styleNotFoundDescriptionCard = css`
  padding: 64px;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.COLOR_595959};
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`

/*
 * 本スタイルは複数箇所で利用されており、
 * 変更時の影響が大きいため、変更しないこと。
 * 変更が必要な場合は同様のスタイルを切り出した上で変更すること
 */
export const stylePageTitle = css`
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: ${colors.COLOR_595959};
`

/*
 * 本スタイルは複数箇所で利用されており、
 * 変更時の影響が大きいため、変更しないこと。
 * 変更が必要な場合は同様のスタイルを切り出した上で変更すること
 */
export const styleAttributeValue = css`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.COLOR_595959};
`

/*
 * 本スタイルは複数箇所で利用されており、
 * 変更時の影響が大きいため、変更しないこと。
 * 変更が必要な場合は同様のスタイルを切り出した上で変更すること
 */
export const styleTextField = css`
  .MuiOutlinedInput-root {
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
    color: ${colors.COLOR_595959};

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.COLOR_CDCDCD};
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.COLOR_F59CAE};
      border-width: 1px;
    }
    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.COLOR_D50000};
    }
  }
  .MuiOutlinedInput-input {
    padding: 0;

    &::placeholder {
      color: ${colors.COLOR_D8D8D8};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.COLOR_CDCDCD};
  }
  .MuiFormHelperText-root {
    margin-top: 5px;
    margin-right: 0px;
    margin-left: 0px;

    &.Mui-error {
      color: ${colors.COLOR_D50000} !important;
    }
  }
  .MuiInputBase-root.Mui-error {
    background-color: ${colors.COLOR_FFF8F9};
  }
`
