import { useCoupleUserContext } from '@/context/cs/session'
import { useError } from '@/context/error'
import { useEffect } from 'react'

type LayoutType = {
  children: React.ReactNode
}
/**
 * Contextの認証状態を確認して、
 * 認証されていない場合は子Componentを表示させない制御を提供します。
 *
 * ※本コンポーネントはCoupleUserContextを利用するため、
 * 上位のComponentにAuthenticationコンポーネントが必要です。
 */
export default function CheckOnlyAuthentication({ children }: LayoutType) {
  const { user, error } = useCoupleUserContext()
  const { setError } = useError()

  useEffect(() => {
    if (error && ![401, 403].includes(error.statusCode)) {
      setError(error.statusCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return <>{user && children}</>
}
