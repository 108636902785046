import { useLoading } from '@/context/loading'
import { EntryAuthControllerApi } from '@/data/gen'
import { defaultConfiguration, invoke, RPCError } from '@/data/rpc'
import { useCallback, useState } from 'react'

export const useLogout = () => {
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<RPCError | null>()
  const { loading, setLoading } = useLoading()

  const logout = useCallback(async () => {
    setLoading(true)
    const authApi = new EntryAuthControllerApi(defaultConfiguration())
    const response = await invoke(() => authApi.entryLogout())
    if (response.success) {
      setSuccess(true)
    } else {
      setError(response.error)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    logout,
    success,
    error,
    loading,
  }
}
