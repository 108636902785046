import { useGuestListUpCoupleUserContext } from '@/context/cs_guest_list_up/session'
import { useError } from '@/context/error'
import { useEffect } from 'react'

type LayoutType = {
  children: React.ReactNode
}
/**
 * Contextの認証状態を確認して、
 * 認証されていない場合は子Componentを表示させない制御とログイン画面への遷移を提供します。
 *
 * ※本コンポーネントはCoupleUserContextを利用するため、
 * 上位のComponentにAuthenticationコンポーネントが必要です。
 */
export default function ForceAuthentication({ children }: LayoutType) {
  const { user, error } = useGuestListUpCoupleUserContext()
  const { setError } = useError()
  useEffect(() => {
    if (error) {
      setError(error.statusCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  return <>{user && children}</>
}
