import { colors } from '@/styles/colors'
import { cs_colors } from '@/styles/cs/colors'
import { css, SerializedStyles } from '@emotion/react'

type ButtonProps = JSX.IntrinsicElements['button'] & {
  icon?: JSX.Element
  label: string
}

type buttonShapeType = 'round' | 'square'
type buttonSizeType = 'sss' | 'ss' | 's' | 'm' | 'l'
// borderの種類としてborderPinkとborderGrayがあり、従来のborderはborderPinkを表していた
// borderをborderPinkに変更するのは今後整理が必要となった際に実施するので、borderGrayを追加する変更に留めている
type buttonStyleType = 'primary' | 'secondary' | 'border' | 'borderGray' | 'text'
type ButtonBaseProps = ButtonProps & {
  buttonShape: buttonShapeType
  buttonSize: buttonSizeType
  buttonStyle: buttonStyleType
}

function ButtonBase({
  buttonSize,
  buttonShape,
  buttonStyle,
  icon,
  label,
  ...props
}: ButtonBaseProps) {
  return (
    <button
      css={[
        styles.button,
        stylesButtonSize[buttonSize],
        stylesButtonShape[buttonShape],
        stylesButtonStyle[buttonStyle],
      ]}
      {...props}
    >
      {icon && <div css={[styles.icon, stylesIconSize[buttonSize]]}>{icon}</div>}
      {label}
    </button>
  )
}

export const RoundSSSBorderGrayButton = (props: ButtonProps) => (
  /**
   * ボタンが小さくてタップしづらいため、ボタンの外側上下4pxにタップエリアを入れる
   * 判定の拡大は擬似要素で実現しており、その設定はstylesButtonSize.sssのCSSに記述している
   */
  <div css={{ padding: '4px 0', width: 'fit-content' }}>
    <ButtonBase buttonSize="sss" buttonShape="round" buttonStyle="borderGray" {...props} />
  </div>
)

export const RoundSSBorderButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="ss" buttonShape="round" buttonStyle="border" {...props} />
)

export const RoundSSBorderGrayButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="ss" buttonShape="round" buttonStyle="borderGray" {...props} />
)

export const RoundMSecondaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="m" buttonShape="round" buttonStyle="secondary" {...props} />
)

export const RoundMBorderButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="m" buttonShape="round" buttonStyle="border" {...props} />
)

export const RoundLPrimaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="l" buttonShape="round" buttonStyle="primary" {...props} />
)
export const RoundLSecondaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="l" buttonShape="round" buttonStyle="secondary" {...props} />
)

export const RoundLBorderButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="l" buttonShape="round" buttonStyle="border" {...props} />
)

export const SquareSPrimaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="s" buttonShape="square" buttonStyle="primary" {...props} />
)

export const SquareSBorderButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="s" buttonShape="square" buttonStyle="border" {...props} />
)

export const SquareMPrimaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="m" buttonShape="square" buttonStyle="primary" {...props} />
)

export const SquareMSecondaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="m" buttonShape="square" buttonStyle="secondary" {...props} />
)

export const SquareMBorderButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="m" buttonShape="square" buttonStyle="border" {...props} />
)

export const SquareMTextButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="m" buttonShape="square" buttonStyle="text" {...props} />
)

export const SquareSSecondaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="s" buttonShape="square" buttonStyle="secondary" {...props} />
)

export const SquareSSPrimaryButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="ss" buttonShape="square" buttonStyle="primary" {...props} />
)

export const SquareSSBorderButton = (props: ButtonProps) => (
  <ButtonBase buttonSize="ss" buttonShape="square" buttonStyle="border" {...props} />
)

const styles = {
  button: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    letter-spacing: 0.5px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  `,
  icon: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

const stylesButtonShape: { [key in buttonShapeType]: SerializedStyles } = {
  round: css`
    border-radius: 50px;
  `,
  square: css`
    border-radius: 8px;
  `,
}

const stylesButtonSize: { [key in buttonSizeType]: SerializedStyles } = {
  l: css`
    height: 56px;
    padding: 0 24px;
    font-size: 14px;
  `,
  m: css`
    height: 44px;
    padding: 0 16px;
    font-size: 12px;
  `,
  s: css`
    height: 40px;
    padding: 0 16px;
    font-size: 10px;
  `,
  ss: css`
    height: 32px;
    padding: 0 16px;
    font-size: 10px;
  `,
  sss: css`
    position: relative;
    height: 20px;
    padding: 0 8px;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0;
    &::before {
      position: absolute;
      top: -5px;
      right: -1px;
      bottom: -5px;
      left: -1px;
      cursor: pointer;
      content: '';
    }
  `,
}

const stylesButtonStyle: { [key in buttonStyleType]: SerializedStyles } = {
  primary: css`
    color: ${colors.COLOR_FFFFFF};
    background: linear-gradient(
      100.43deg,
      ${colors.COLOR_EA6077} 3.17%,
      ${colors.COLOR_F67A7F} 95.68%
    );
    :hover {
      background: linear-gradient(
        100.43deg,
        ${colors.COLOR_E9586C} 3.17%,
        ${colors.COLOR_F66E73} 95.68%
      );
    }
    :disabled {
      background: ${colors.COLOR_E7E0E0};
    }
  `,
  secondary: css`
    color: ${colors.COLOR_EA6077};
    background: linear-gradient(
      92.76deg,
      rgba(234, 96, 119, 0.2) 0%,
      rgba(255, 162, 133, 0.2) 100%
    );
    backdrop-filter: blur(50px);
    :hover {
      background: linear-gradient(92.76deg, ${colors.COLOR_F9D0D6} 0%, ${colors.COLOR_FFE2D9} 100%);
    }
    :disabled {
      color: ${colors.COLOR_FFFFFF};
      background: ${colors.COLOR_E7E0E0};
    }
  `,
  border: css`
    /* TODO: XYSAAS-9930 本当はこれをやりたいが border-image と border-radius は同時には効かないらしい
      border-image-source: linear-gradient(100.43deg, ${colors.COLOR_EA6077} 3.17%, ${colors.COLOR_F67A7F} 95.68%); */
    color: ${colors.COLOR_EA6077};
    background: ${colors.COLOR_FFFFFF};
    border: 1px solid ${colors.COLOR_EA6077};
    :hover {
      background: linear-gradient(0deg, rgba(234, 96, 119, 0.1), rgba(234, 96, 119, 0.1)),
        ${colors.COLOR_FFFFFF};
    }
    :disabled {
      color: ${colors.COLOR_E7E0E0};
      border: 1px solid ${colors.COLOR_E7E0E0};
    }
  `,
  borderGray: css`
    color: ${cs_colors.textAndIcon.black_textGray};
    background: ${colors.COLOR_FFFFFF};
    border: 1px solid ${cs_colors.border.lightGray_line};
    backdrop-filter: blur(40px);
    -webkit-tap-highlight-color: transparent;
    @media (hover: hover) {
      :hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  `,
  text: css`
    color: ${colors.COLOR_EA6077};
    background-color: transparent;
    @media (hover: hover) {
      :hover {
        background: linear-gradient(0deg, rgba(234, 96, 119, 0.1), rgba(234, 96, 119, 0.1)),
          ${colors.COLOR_FFFFFF};
      }
    }
    :disabled {
      color: ${colors.COLOR_E7E0E0};
    }
  `,
}

const stylesIconSize: { [key in buttonSizeType]: SerializedStyles } = {
  l: css`
    width: 22px;
    height: 22px;
    margin-right: 8px;
  `,
  m: css`
    width: 16px;
    height: 16px;
    margin-right: 4px;
  `,
  s: css`
    width: 16px;
    height: 16px;
    margin-right: 4px;
  `,
  ss: css`
    width: 16px;
    height: 16px;
    margin-right: 4px;
  `,
  sss: css`
    width: 12px;
    height: 12px;
    margin-right: 2px;
  `,
}
