/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GetClientTypeResponse
 */
export interface GetClientTypeResponse {
  /**
   *
   * @type {string}
   * @memberof GetClientTypeResponse
   */
  clientTypeEnum: GetClientTypeResponseClientTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum GetClientTypeResponseClientTypeEnum {
  invitationOnly = 'INVITATION_ONLY',
  guestListUpAndInvitation = 'GUEST_LIST_UP_AND_INVITATION',
}
